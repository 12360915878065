import { html, LitElement, nothing } from 'lit'
import { property, state } from 'lit/decorators.js'
import { styles } from './styles'
import { TmlNavigationDocumentData } from '@repo/prismic-data/prismicio-types'
import { desktopNavigationStyles } from './DesktopNavigation.styles'
import DesktopNavigation from './DesktopNavigation'
import { mobileNavigationStyles } from './MobileNavigation.styles'
import MobileNavigation from './MobileNavigation'
import { booleanConverter } from '../../utils/converters'

declare global {
  interface HTMLElementTagNameMap {
    'tml-navigation': TMLNavigation
  }
}

// Event listeners
const mediaQuery = window.matchMedia('(min-width: 992px)')

mediaQuery.addEventListener('change', (e) => {
  document.querySelector('tml-navigation')?.setIsDesktop(e.matches)
})

export class TMLNavigation extends LitElement {
  static styles = [styles, desktopNavigationStyles, mobileNavigationStyles]

  @state()
  private _isDesktop = mediaQuery.matches

  @state()
  _openDesktopMenuIndex: number | undefined

  @state()
  _openMobileMenu = false

  @state()
  _openMobileMenuI: number | undefined

  @state()
  _openMobileMenuII: number | undefined

  @state()
  _openMobileMenuIII: number | undefined

  @state()
  width?: number

  @state()
  closestNavUrl?: string

  @state()
  closestNavId?: string

  @property()
  language?: string

  @property()
  private _desktopNavigation = new DesktopNavigation(this)

  @property()
  private _mobileNavigation = new MobileNavigation(this)

  @property({ converter: booleanConverter })
  festivalButtons?: boolean

  // Default value for attribute = true
  // Set it explicitly to show this is an attribute
  @property({ attribute: true })
  image?: string

  // Prismic loader data
  @property({ attribute: false })
  data: Record<string, TmlNavigationDocumentData> | undefined

  constructor() {
    super()
    this.setIsDesktop(mediaQuery.matches)
  }

  loadLanguage(locale: string) {
    this.language = locale
  }

  setIsDesktop(matches: boolean) {
    this._isDesktop = matches
  }

  render() {
    const data = this.data?.[this.language || 'en-us']
    if (!data) return nothing

    return html`<nav>
      ${this._isDesktop
        ? this._desktopNavigation.render(data)
        : this._mobileNavigation.render(data)}
    </nav>`
  }
}

// Define only if not already exists
if (!window.customElements.get('tml-navigation')) {
  window.customElements.define('tml-navigation', TMLNavigation)
}
