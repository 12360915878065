import { css } from 'lit'

export const desktopNavigationStyles = css`
  .desktop-background {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: -100vh;
    left: 0px;
    right: 0px;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  }

  .desktop-background .background-image {
    position: absolute;
    width: 100%;
    height: 100vh;
    inset: 0;
    top: calc(100vh - 80px);
    transition: transform 0.3s ease-in-out;
  }

  /* NOTE: To prevent the entire navigation bar from being slightly transparent on the edges,
* caused by the blur filter,
* apply the blur filter on a element which inherits most properties from the main element.
*/
  .desktop-background .background-filter {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    inset: 0;
    -webkit-backdrop-filter: brightness(60%) blur(16px);
    backdrop-filter: brightness(60%) blur(16px);
  }

  .desktop-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 64px;
  }

  .desktop-main .tml-logo {
    height: 32px;
    transition: transform 0.3s ease-in-out;
  }

  .desktop-main .tml-logo:hover {
    transform: scale(1.1);
  }

  .desktop-main .tml-logo svg {
    height: 32px;
  }
  .desktop-main .navigation-buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-inline: 10px;
    width: fit-content;
    height: 30px;
    border-radius: 9999px;
    border: 1px solid transparent;
    background-color: hsla(0, 100%, 100%, 0.1);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
  }

  .desktop-main .navigation-buttons a:hover {
    text-decoration: none;
    border: 1px solid #ffffff;
  }

  .desktop-main .navigation-buttons span {
    font-size: 12px;
    font-weight: 700;
  }
  .desktop-main .navigation-buttons svg {
    width: 16px;
  }

  .desktop-main .tml-account-link:hover {
    border-color: white;
  }

  .desktop-main .top-navigation {
    height: 80px;
  }

  .desktop-main .top-navigation button {
    height: 100%;
    padding-block: 24px;
    padding-inline: 16px;
    font-weight: 700;
  }

  .desktop-main .top-navigation button:hover {
    border-bottom: 1px solid white;
  }

  .desktop-main .top-navigation-submenu {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 64px;
    top: 80px;
    left: 0;
    right: 0;
    padding-block: 24px;
    padding-inline: 64px;
    transition: all 0.3s ease-in;
  }

  .desktop-main .top-navigation-submenu[data-visible='false'] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .desktop-main .site-navigation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
  }

  .desktop-main .site-navigation-main a {
    display: block;
    font-weight: 700;
  }

  .desktop-main .site-navigation-main[data-visible='true'] a {
    margin-block-end: 8px;
  }
`
